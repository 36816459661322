import React, { Component } from 'react';
import AboutAds from '../common/components/AboutAds';
import ScrollToTop from '../common/components/ScrollToTop';

class AboutAdsPage extends Component {
  componentDidMount(){
    window.analytics.page("About Ads");
  }
  render() {
    return (
      <ScrollToTop>
        <AboutAds />
      </ScrollToTop>
    );
  }
}

export default AboutAdsPage;
