import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import LocalizedStrings from 'react-localization';

const strings = new LocalizedStrings({
  en: {
    title: 'Ballnamic',
    page: 'Accessibility',
  },
});

class AboutAds extends Component {
  static propTypes = {
    scrollable: PropTypes.bool,
  };

  clickPreferences(){
    window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
  }
  clickDoNotSell(){
    window.Osano.cm.showDoNotSell();
  }

  render() {
    const { scrollable } = this.props;
    return (
      <div className={`container legal legal-container ${scrollable ? 'about-ads__scrollable' : ''}`}>
        <Helmet>
          <title>{`${strings.page} - ${strings.title}`}</title>
        </Helmet>
        <div className="about-ads--section__wrapper">
        <section>
            <h3>ACCESSIBILITY STATEMENT</h3>
            <p>
              Ballnamic is committed to providing an inclusive shopping experience to all our customers and aims to facilitate the accessibility and usability of our website by complying with the Americans with Disabilities Act, and conforming to, at minimum, Web Content Accessibility Guidelines 2.2 Level AA, a set of internationally recognized guidelines for digital accessibility. We view accessibility as an ongoing effort and are continually seeking solutions that will bring all areas of our website to the same level of overall web accessibility. Our internal team and independent partners audit, review and evaluate our efforts regularly.
            </p>
            <br></br>
            <p>
              While we strive to ensure digital accessibility, it is not always possible to do so, especially as it relates to content owned or managed by third parties. Ballnamic is not responsible for ensuring that third-party content, software, add-ins, etc., which may be located on or linked to Ballnamic’s websites but are not owned or managed by Ballnamic, conform to the relevant WCAG standards and other applicable regulations.
            </p>
            <br></br>
            <p>
              If you have trouble accessing any part of this website, please feel free to call us at 1-888-309-9876 or email us at <a href="mailto:compliance@ping.com">compliance@ping.com</a> and we will work with you to provide the information, item, or transaction you seek through a communication method that is accessible for you consistent with applicable law.
            </p>
            <h3>FEEDBACK</h3>
            <p>
              Please call us at 1-888-309-9876 or email us at <a href="mailto:compliance@ping.com">compliance@ping.com</a> if you have any feedback or suggestions as to how we could improve the accessibility of this website.
            </p>
          </section>
        </div>
      </div>
    );
  }
}

export default AboutAds;