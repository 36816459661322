import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import LocalizedStrings from 'react-localization';

const strings = new LocalizedStrings({
  en: {
    title: 'Ballnamic',
    page: 'Privacy',
  },
});

class PrivacyPolicy extends Component {
  static propTypes = {
    scrollable: PropTypes.bool,
  };
  render() {
    const { scrollable } = this.props;
    return (
      <div className={`container legal legal-container ${scrollable ? 'privacy-policy__scrollable' : ''}`}>
        <Helmet>
          <title>{`${strings.page} - ${strings.title}`}</title>
        </Helmet>
        <h2>PING, Inc. Privacy Policy - Your Privacy Rights</h2>
        <p>Effective Date: March 19, 2020</p>
        <br />
        <p>Last Updated: September 19, 2024</p>
        <div className="privacy-policy--section__wrapper">
          <section>
            <h3>Scope</h3>
            <p className="emphasis">
              This Privacy Policy ("Policy") describes how PING, Inc. ("PING," "we," or "us") treats personal
              information collected on our websites, mobile applications, and any other platforms potential consumers
              interact with (the “Platform”) as well as information collected offline.
            </p>
            <br />
            <p className="emphasis">
              If you are visually impaired, you may access this notice through your browser’s audio reader by
              visiting&nbsp;
              <a href="https://ping.com/en-us/privacy">https://ping.com/en-us/privacy</a>.
            </p>
          </section>

          <section id="types-of-information-we-collect">
            <h3>Types of information we collect</h3>
            <p>
              PING collects information, including some sensitive personal information, from you and about you. Here are
              some examples of the information we may collect:
            </p>
            <ul>
              <li>
                Contact Information. For example, we may collect your name, mailing address, telephone or mobile number,
                and email address to help share information about our products or services.
              </li>
              <li>
                Payment Information. For example, we may collect your information such as your payment method and
                billing address to perform our contractual obligations and provide you with products or services.
              </li>
              <li>
                Account Information. If you create an account on our Platform, we will collect your username and
                password to facilitate an easy checkout process and save consumer preferences.
              </li>
              <li>
                Fitting Information. For example, if you utilize our fitting tools, we may collect information that you
                submit that helps us fit you to the correct products.
              </li>
              <li>
                Physical Activity Information. For example, we may collect information about your physical activity
                while you are using our mobile application, or another device connected to our mobile application. This
                may include your step count, heart rate, or calorie count that help the user operate our products and
                services.
              </li>
              <li>
                Golf Information. We receive a variety of information related to your golf game. For example, (1) you
                may be able to upload swing videos showing you and your swing, (2) we may collect detailed information
                from you in an effort to determine the correct equipment or ball for your game, and (3) we may receive
                information from sensors in “smart clubs” as well as golf related information provided by you via an app
                or website. This information may be received by PING from third-party sensor or application providers.
              </li>
              <li>
                Information You Post. We may collect information you post in a public space on our Platform, such as on
                our product reviews feature. We may also collect photographs or videos that you voluntarily post to our
                Platform.
              </li>
              <li>
                Social Media Information. We may collect information you post on our social media pages, including your
                social media profile information and information posted on your page, to promote and improve our
                products and services.
              </li>
              <li>
                Information You Submit. We may collect information when you send us a message through the “Customer
                Service” page or similar features on our Platform. We may also collect the information you submit when
                you enter a promotion.
              </li>
              <li>
                Demographic Information. We may collect your birthdate, age, gender, and zip code, which helps us
                understand our users and provides tailored services.
              </li>
              <li>
                Device Information. For example, we may collect the type of device you use to access our Platform. We
                may also collect your device hardware model and unique identifier, IP address or mobile operating
                system.
              </li>
              <li>
                Location Information. For example, we may collect precise location information from your device to
                better assist the consumer and provide tailored services. This may include information about your exact
                location when you use our Platform. We may also collect this information in the background when our
                mobile applications are not in use. For more information about your options related to the collection of
                your location information, see the <a href="#choices">Choices</a> section below.
              </li>
              <li>
                Employment Information: If you apply for a job posting, or become an employee, we collect information
                necessary to process your application or to retain you as an employee. This may include, among other
                things, your Social Security Number. Providing this information is required for employment.
              </li>
              <li>
                Other Information. If you use our website, we may collect information about the browser you are using.
                We might look at what site you came from, or what site you visit when you leave us. If you use our app,
                we might look at how often you use the app and where you downloaded it. We may also collect information
                about your mobile network.
              </li>
            </ul>
          </section>

          <section>
            <h3>How we collect your information</h3>
            <p className="emphasis">
              PING collects your information in different ways. Below are some examples of how we may collect your
              information on our Platform.
            </p>
            <ul>
              <li>
                <span className="underline">Directly From You</span>. For example, when you:
                <ul>
                  <li>Register for an account.</li>
                  <li>Purchase products or services through our Platform.</li>
                  <li>Sign up to receive promotional communications.</li>
                  <li>Use our Webfit, Ballnamic, iPing, or other online application(s).</li>
                  <li>Submit a request for club repairs.</li>
                  <li>Participate in one of our promotional sweepstakes, contests, surveys, or focus groups.</li>
                  <li>Post a product review.</li>
                  <li>Submit a request to our customer service team.</li>
                  <li>Interact with PING social media pages.</li>
                </ul>
              </li>
              <li>
                <span className="underline">Passively</span>. For example, when you:
                <ul>
                  <li>Install and use PING mobile apps.</li>
                  <li>Visit and navigate our Platform on any device.</li>
                  <li>Enable location-based features on our Platform.</li>
                </ul>
              </li>
              <p>
                We may also collect information about users over time and across different websites, apps, and devices
                when you use the Platform. Third parties also collect personal information this way on our Platform.
              </p>
            </ul>
            <ul>
              <li>
                <span className="underline">From Third-Parties</span>. We may receive information about you from other
                sources. For example, this may include receiving information from:
                <ul>
                  <li>
                    Our business partners, including company contacts, which may include Apple HealthKit and Health App,
                    analytics vendors, providers of golf sensors or mobile applications, and companies that co-sponsor
                    our promotions.
                  </li>
                  <li>Social media sites, including Facebook, Twitter, YouTube and Instagram.</li>
                  <li>
                    Third party sites, including those that allow you to integrate your third-party account with your
                    PING account.
                  </li>
                  <li>
                    Companies that provide information to supplement what we already know about you. Certain third
                    parties might link your name or email address to other information they have collected. That might
                    include past purchases made offline or online. It might also include online usage information.
                  </li>
                </ul>
              </li>
              <li>
                <span className="underline">By Combining Information</span>. For example, we may:
                <ul>
                  <li>Combine information that we collect offline with information we collect through our Platform.</li>
                  <li>
                    Combine information we collect about you from the different devices you use to access our Platform.
                  </li>
                  <li>Combine information we get from third parties with information we already have about you.</li>
                </ul>
              </li>
            </ul>
          </section>

          <section>
            <h3>How we use your information</h3>
            <p>Examples of how we may use your information that we collect through our Platform include:</p>

            <ul>
              <li>
                <span className="underline">To Provide Our Products and Services</span>. This could include fulfilling
                your requests for products or services. It could also include presenting golf analytics, processing
                purchases, or other transactions.
              </li>
              <li>
                <span className="underline">To Improve Our Products and Services</span>. We may use your information to
                make our Platform, products, and services better. We may also use your information to customize your
                experience with us.
              </li>
              <li>
                <span className="underline">To Understand Your Interests</span>. For example, we may use your
                information to better understand what products interest you.
              </li>
              <li>
                <span className="underline">To Respond to Your Requests or Questions</span>. This may include responding
                to your feedback or notifying you if you win a promotion.
              </li>
              <li>
                <span className="underline">To Communicate With You</span>. We may communicate with you about your
                account or our relationship. We may also contact you about this Policy or our Platform terms and
                conditions.
              </li>
              <li>
                <span className="underline">To Determine Your Eligibility</span>. We may use your information to verify
                your identity or determine your eligibility for some of our products, services and promotions. For
                example, this may include verifying your age, date of birth and state of residence.
              </li>
              <li>
                <span className="underline">For Marketing Purposes</span>. We may provide you with information about new
                products and special offers. We might use your information to serve you ads about products and offers.
                We might tell you about new features or updates. These might be third party offers or products we think
                you might find interesting. We may also use your information to send you electronic communications. We
                and our partners may engage in interest-based advertising using information gathered across multiple
                websites, devices, or other platforms. For more information about your choices related to these
                communications, see the <a href="#choices">Choices</a> section below.
              </li>
              <li>
                <span className="underline">For Security Purposes</span>. This could include protecting our company and
                consumers who use our products and services. It may also include protecting our Platform.
              </li>
              <li>
                <span className="underline">To Send Push Notifications</span>. If you use our mobile apps, we may send
                you push notifications about new products or special offers.
              </li>
              <li>
                <span className="underline">Deidentified Data</span>. We generally do not use deidentified data, but to
                the extent we maintain and use personal information in a deidentified form, we will not attempt to
                reidentify the information, except for the purpose of determining whether our deidentification processes
                satisfy our legal obligations.
              </li>
              <li>As otherwise permitted by law.</li>
              <li>As we may otherwise notify you.</li>
              <p>Note that we do not sell your personal information for money.</p>
            </ul>
          </section>

          <section>
            <h3>How we share your information</h3>
            <p>We may share your information in the following ways:</p>

            <ul>
              <li>
                <span className="underline">Internally</span>.We may share your information within PING, Inc or with our
                corporate affiliates (e.g., subsidiaries, joint ventures, or other companies under common control). The
                categories of information we may share include: any of the categories described above under{' '}
                <a href="#types-of-information-we-collect"> Types of information we Collect</a>.
              </li>
              <li>
                <span className="underline">On Our Platform</span>. For example, we may display information you post on
                our product review features. We may also display information you submit in connection with a promotion.
                The categories of information we may share include Information You Post, Social Media Information, and
                Information You Submit.
              </li>
              <li>
                <span className="underline">With Our Service Providers</span>. We may share your information with third
                parties who perform services on our behalf. For example, this may include merchandise vendors and
                payment processors. It may also include companies that send emails or deliver advertisements on our
                behalf or help us run our Platform. The categories of information we may share include any of the
                categories described above under{' '}
                <a href="#types-of-information-we-collect"> Types of information we Collect</a>.
              </li>
              <li>
                <span className="underline">With Our Business Partners</span>. For example, this may include a third
                party that co-sponsors a contest or promotion. We may also share information with business partners
                including Apple HealthKit, Health App and analytics vendors. The categories of information we may share
                include Contact Information, Physical Activity Information, Information You Submit, Demographic
                Information, and Other Information.
              </li>
              <li>
                <span className="underline">
                  With Any Successors to All or Part of Our Business or One of Our Brands
                </span>
                . For example, if PING merges with, acquires, or is acquired, or sells a brand or part of its business
                to another business entity. This may include an asset sale, corporate reorganization, or other change of
                control. We may transfer our customer information as part of such a transaction or as stand-alone
                assets, including at the negotiation stage. The categories of information we may share include any of
                the categories described above under{' '}
                <a href="#types-of-information-we-collect"> Types of information we Collect</a>.
              </li>
              <li>
                <span className="underline">To Comply With the Law or To Protect Ourselves</span>. For example, this
                could include responding to a court order or subpoena. It could also include sharing information if a
                government agency or investigatory body requests. We might share information when we are investigating a
                potential fraud. This could include fraud we think has occurred during a sweepstakes or promotion. We
                may also share information if you are the winner of a sweepstakes or other contest with anyone who
                requests a winner’s list. The categories of information we may share include any of the categories
                described above under <a href="#types-of-information-we-collect"> Types of information we Collect</a>.
              </li>
              <li>
                <span className="underline">For Other Reasons With Your Consent</span>. We may disclose your information
                to other third parties when we have your consent or direction to do so. The categories of information we
                may share include any of the categories described above under{' '}
                <a href="#types-of-information-we-collect"> Types of information we Collect</a>.
              </li>
            </ul>
          </section>

          <section>
            <h3>Children under 13</h3>
            <p>
              The Platform where this Policy is located are meant for adults. We do not knowingly collect personally
              identifiable data from persons under the age of 13 and strive to comply with the provisions of COPPA (The
              Children’s Online Privacy Protection Act). If you are a parent or legal guardian and think your child
              under 13 has provided us with information, please contact us at{' '}
              <a href="mailto:compliance@ping.com">compliance@ping.com</a>. You can also write to us at the address
              listed at the end of this website Policy. Please mark your inquiries “COPPA Information Request.” Parents,
              you can learn more about how to{' '}
              <a href="https://www.consumer.ftc.gov/articles/0031-protecting-your-childs-privacy-online">
                protect children's privacy on-line here
              </a>
              .
            </p>
          </section>

          <section id="choices">
            <h3>Choices regarding your information</h3>
            <p>
              You have certain choices about how we use your information. Certain choices you make are browser and
              device specific.
            </p>
          </section>

          <section>
            <h4>Marketing Communications:</h4>
            <p>
              You can opt out of receiving our marketing communications. Note that you will still receive transactional
              messages from us, including information about your account and responses to your inquiries. To opt out of
              receiving our marketing communications, you can follow the instructions included with the communication.
            </p>
          </section>

          <section>
            <h4>Mobile Application and Location Based Services:</h4>
            <ul>
              <li>
                You may opt out of our mobile application's push notification by adjusting the settings on your mobile
                device.
              </li>
              <li>
                If you have previously opted into PING's collection and use of location-based information through our
                mobile applications, you may opt-out by adjusting the settings on your mobile device.
              </li>
              <li>
                You may also opt-out of location-based information collection by PING if you uninstall all PING mobile
                apps from your devices.
              </li>
            </ul>
          </section>

          <section>
            <h4>Cookies & Other Tracking Technologies:</h4>
            <ul>
              <li>
                To learn how to manage how we – and our vendors – use cookies and other tracking tools, and to read our{' '}
                <strong>Do Not Track Policy</strong>, please <a href="https://ping.com/legal/about-ads">click here</a>.
              </li>
              <li>Options you select are browser and device specific.</li>
            </ul>
          </section>

          <section>
            <h3>Your privacy choices</h3>
            <p>
              Some jurisdictions give you a right to make the following choices regarding your personal information:
            </p>
            <ol>
              <li>
                <span className="underline">Access To Your Personal Information</span>. You may request access to your
                personal information (or confirmation that we have information about you) by contacting us at the
                address described below. If required by law, upon request, we will grant you access to the personal
                information that we have about you. Note that California residents may be entitled to ask us for a
                notice describing what categories of personal information (if any) we share with third parties or
                affiliates for direct marketing.
              </li>
              <li>
                <span className="underline">Changes To Your Personal Information</span>. We rely on you to update and
                correct your personal information. Most of our websites allow you to modify or delete your account
                profile. If our website does not permit you to update or correct certain information, you can contact us
                at the address described below in order to request that your information be modified. Note that we may
                keep historical information in our backup files as permitted by law.
              </li>
              <li>
                <span className="underline">Deletion Of Your Personal Information</span>. You may request that we delete
                your personal information by contacting us at the address described below. If required by law we will
                grant a request to delete information, but you should note that in many situations we must keep your
                personal information to comply with our legal obligations, resolve disputes, enforce our agreements, or
                for another business purposes.
              </li>

              <li>
                <span className="underline">Portability</span>. You may request we provide your information in a common
                portable format. When we required to do so by law, we will provide this information to you or another
                party as required.
              </li>
              <li>
                <span className="underline">Opt-out of Sharing for Targeted Advertising</span>. You may opt-out of
                online tracking based targeted advertising (e.g., cookies) by clicking on the “Do Not Sell or Share My
                Personal Information” link. We also recognize Global Privacy Control (GPC) signals and other
                user-enabled opt-out preference signals as valid opt-out requests where required by applicable law.
                Please note that your opt-out preference signal will be applied to only your current browser and device.
                To learn more about the GPC, you can visit its website{' '}
                <a target="_blank" rel="noreferrer" href="https://globalprivacycontrol.org/">
                  here
                </a>
              </li>
            </ol>
            <p>
              You may exercise the rights described above by contacting us as indicated in the “How to contact us”
              section below. We do not discriminate against individuals that exercise their privacy rights under
              applicable law. If you disagree with how we handled a request, you may appeal our decision by contacting
              us with the subject line “Appeal.”
            </p>
            <p>
              Note that, as required by law, we will require you to prove your identity. We may conduct an identity
              verification by phone call or email. Depending on your request, we will ask for information such as your
              name, the last item you purchased from us, or the date of your last purchase from us. We may also ask you
              to provide a signed declaration confirming your identity.
            </p>
            <p>
              In some circumstances, you may designate an authorized agent to submit requests to exercise certain
              privacy rights on your behalf. We will require verification that you provided the authorized agent
              permission to make a request on your behalf. You must provide us with a copy of the signed permission you
              have given to the authorized agent to submit the request on your behalf and verify your own identity
              directly with us.
            </p>
            <p>
              If you are an authorized agent submitting a request on behalf of an individual, you must attach a copy of
              the following information to the request:
            </p>
            <ol>
              <li>
                A completed Authorized Agent Designation Form indicating that you have authorization to act on the
                individual’s behalf.
              </li>
              <li>
                If you are a business, proof that you are registered with the Secretary of State to conduct business in
                California.
              </li>
            </ol>
            <p>If we do not receive both pieces of information, the request will be denied.</p>
          </section>

          <section>
            <h3>Security</h3>
            <p>
              The Internet is not 100% secure. We cannot promise that your use of our Platform will be completely safe.
              We encourage you to use caution when using the Internet. We use reasonable means to safeguard personal
              information under our control. A username and a password are needed to access certain areas of the PING
              Platform. It is your responsibility to protect your username and password. In the event that we are
              required by law to inform you of a breach to your personal information we may notify you electronically,
              in writing, or by telephone, if permitted to do so by law.
            </p>
          </section>
          <section>
            <h3>How long we retain information</h3>
            <p>
              We retain your personal information for only as long as necessary to fulfil the purposes outlined in this
              Policy, including for the purposes of satisfying any legal, accounting, or reporting requirements, unless
              a longer retention period is required or permitted by law. To determine the appropriate retention period
              for personal information, we consider the amount, nature, and sensitivity of the information, the
              potential risk of harm from unauthorized use or disclosure of the information, the purposes for which we
              obtained the information and whether we can achieve those purposes through other means, as well as
              applicable legal requirements.
            </p>
          </section>

          <section>
            <h3>Storage of information</h3>
            <p>
              Information we maintain may be stored in the United States. If you live outside of the United States, you
              understand and agree that we may transfer your personal information to the United States. This Platform is
              intended for use in the United States and is subject to the laws of the United States, which may not
              provide the same level of protections as those in your own country.
            </p>
          </section>

          <section>
            <h3>Links</h3>
            <p>
              Our Platform may contain links to other third-party sites that are not governed by this Policy. If you
              click on a link to a third-party site, you will be taken to a site we do not control. We are not
              responsible for the privacy practices used by third-party sites. We suggest that you read the privacy
              policies of those sites carefully. We are not responsible for these third-party sites.
            </p>
          </section>

          <section>
            <h3>How to contact us</h3>
            <p>
            If you have any questions, comments or concerns with respect to our privacy practices or this Policy, wish to exercise your privacy rights, or need to access this Policy in an alternative format due to having a disability, please feel free to contact us at{' '}
              <a href="mailto:compliance@ping.com">compliance@ping.com</a> or call us at{' '}
              <a href="tel:+1-800-474-6434">1-800-474-6434</a>. You may also write to us at the following address:
            </p>
          </section>

          <section>
            <address>
              PING, Inc.
              <br />
              Attn: PING Compliance
              <br />
              2201 West Desert Cove Avenue
              <br />
              Phoenix, AZ 85029
            </address>
          </section>

          <section>
            <h3>Changes in Policy</h3>
            <p>
              From time to time, we may change our Policy. We will notify you of any material changes to our Policy as
              required by law. We will also post an updated copy on our Platform. Please check our Platform periodically
              for updates.
            </p>
          </section>

          <section>
            <h3>Additional information for California Residents</h3>
            <p>
              California law requires organizations to disclose whether certain categories of information are collected,
              “sold,” “shared” for purposes of cross-context behavioral advertising, or disclosed for an organization’s
              “business purpose” (as those terms are defined under California law). You can find a list of the
              categories of information that we collect and share{' '}
              <a target="_blank" rel="noreferrer" href="https://ping.com/en-us/legal/privacy/california-privacy-rights">
                here
              </a>
            </p>
          </section>
        </div>
      </div>
    );
  }
}

export default PrivacyPolicy;
