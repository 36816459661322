import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import * as Sentry from '@sentry/browser';
import compose from 'lodash/flowRight';
import Login from './auth/Login';
import PostLogin from './auth/PostLogin';
import PostLogout from './auth/PostLogout';
import Logout from './auth/Logout';
import Redirector from './auth/Redirector';
import Registration from './auth/Registration';
import LandingPage from './landing-page';
import ErrorBoundary from './common/components/ErrorBoundary';
import ForceReloadPrompt from './common/components/ForceReloadPrompt';
import AuthenticatedApp from './AuthenticatedApp';
import SomethingWentWrong from './common/components/SomethingWentWrong';
import StaticResults from './fitting/Questionnaire/Results/StaticResults';
import NoAccess from './auth/NoAccess';
import NotFound from './NotFound';
import LibraryLayout from './common/layouts/LibraryLayout';
import Article from './library/Article';
import Library from './library';
import PreviewLayout from './common/layouts/PreviewLayout';
import PrivacyPolicyPage from './legal/PrivacyPolicyPage';
import AboutAdsPage from './legal/PrivacyAdsPage';
import AccessibilityPage from './legal/AccessibilityPage'
import PrivacyCaliforniaPage from './legal/PrivacyCaliforniaPage';
import TermsOfUsePage from './legal/TermsOfUsePage';
import { ToastContainer, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-vis/dist/style.css';
import 'tippy.js/dist/tippy.css';
import { initializeGTM } from './common/utils/gtm';
import LocalizedStrings from 'react-localization';
import FAQ from './faq';
import Purchase from './purchase';
import Affiliate from './affililate-marketing';
import PurchaseFormProvider from './context/PurchaseFormContext';
import ShareFormProvider from './context/ShareFormContext';

const strings = new LocalizedStrings({
  en: {
    title: 'Ballnamic',
  },
});

class App extends Component {
  componentDidMount() {
    this.unlisten = this.props.history.listen(() => {
      Sentry.addBreadcrumb();
    });

    initializeGTM();
    //window.analytics.page();
  }

  componentWillUnmount() {
    if (this.unlisten) {
      this.unlisten();
    }
  }

  render() {
    if (this.props.shouldUpdate) {
      return (
        <div className="main-layout">
          <ErrorBoundary>
            <div className="main-layout__children">
              <ForceReloadPrompt />
            </div>
          </ErrorBoundary>
        </div>
      );
    }

    if (this.props.error) {
      return <SomethingWentWrong />;
    }

    return (
        <ShareFormProvider>
          <PurchaseFormProvider>
        <div className="main-layout">
          <ToastContainer hideProgressBar position="top-center" transition={Flip} />
          <Helmet>
            <title>{strings.title}</title>
          </Helmet>
          <ErrorBoundary>
            <div className="main-layout__children">
              <Switch>
                <Route exact path="/" component={LandingPage} />
                <Route exact path="/no-access" component={NoAccess} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/registration" component={Registration} />
                <Route exact path="/privacy" component={PrivacyPolicyPage} />
                <Route exact path="/accessibility" component={AccessibilityPage} />
                <Route exact path="/about-ads" component={AboutAdsPage} />  
                <Route exact path="/privacy/california-privacy-rights" component={PrivacyCaliforniaPage} />
                <Route exact path="/terms-of-use" component={TermsOfUsePage} />
                <Route exact path="/redirect" component={Redirector} />
                <Route exact path="/post-login" component={PostLogin} />
                <Route exact path="/post-logout" component={PostLogout} />
                <Route exact path="/logout" component={Logout} />
                <Route path="/results/:resultsId" component={StaticResults} />
                <Route path="/admin" component={AuthenticatedApp} />
                <Route path="/profile" component={AuthenticatedApp} />
                <Route path="/my-account" component={AuthenticatedApp} />
                <Route path="/my-fitting" component={AuthenticatedApp} />
                <Route path="/redeem" component={Purchase} />
                <Route
                  path="/library/articles/:slug/preview"
                  render={props => (
                    <PreviewLayout>
                      <Article {...props} isPreview />
                    </PreviewLayout>
                  )}
                />
                <Route
                  exact
                  path="/library"
                  render={props => (
                    <LibraryLayout>
                      <Library {...props} />
                    </LibraryLayout>
                  )}
                />
                <Route
                  exact
                  path="/library/articles/:slug"
                  render={props => (
                    <LibraryLayout>
                      <Article {...props} />
                    </LibraryLayout>
                  )}
                />
                <Route
                  exact
                  path="/faq"
                  render={props => (
                    <LibraryLayout>
                      <FAQ {...props} />
                    </LibraryLayout>
                  )}
                />
                <Route
                  exact
                  path="/affiliate"
                  render={props => (
                    <LibraryLayout>
                      <Affiliate {...props} />
                    </LibraryLayout>
                  )}
                />
                <Route path="*" component={NotFound} />
              </Switch>
            </div>
          </ErrorBoundary>
        </div>
        </PurchaseFormProvider>
      </ShareFormProvider>
    );
  }
}

export default compose(
  connect(state => ({
    shouldUpdate: state.shouldUpdate,
    error: state.error,
  })),
  withRouter
)(App);
