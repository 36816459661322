import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import LocalizedStrings from 'react-localization';

const strings = new LocalizedStrings({
  en: {
    title: 'Ballnamic',
    page: 'About Ads',
  },
});

class AboutAds extends Component {
  static propTypes = {
    scrollable: PropTypes.bool,
  };

  clickPreferences(){
    window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
  }
  clickDoNotSell(){
    window.Osano.cm.showDoNotSell();
  }

  render() {
    const { scrollable } = this.props;
    return (
      <div className={`container legal legal-container ${scrollable ? 'about-ads__scrollable' : ''}`}>
        <Helmet>
          <title>{`${strings.page} - ${strings.title}`}</title>
        </Helmet>
        <h2>PING, Inc. About Ads Policy</h2>
        <p>Effective Date: August 2, 2023</p>
        <div className="about-ads--section__wrapper">
          <section>
            <h3>WE USE TRACKING TECHNOLOGIES</h3>
            <p>
              We collect personal information about users over time and across different websites, apps and devices when you use this Platform. Third parties also collect personal information this way on our Platform. To do this, we use several common tracking tools. Our vendors and partners may also use these tools. These may include browser cookies. We, or our vendors and partners, may also use web beacons, flash cookies, and other tracking technologies.
            </p>
          </section>

          <section>
            <h3>WE USE TRACKING TECHNOLOGIES FOR A VARIETY OF REASONS</h3>
            <ul>
              <li>To recognize new or past users.</li>
              <li>To store your password if you have registered on our site.</li>
              <li>To improve our Platform, products, or services.</li>
              <li>To serve you with interest-based or targeted advertising (see below for more on interest-based advertising).</li>
              <li>To observe your behaviors and browsing activities over time across multiple devices or other platforms.</li>
              <li>To better understand the interests of our website visitors.</li>
            </ul>
          </section>

          <section>
            <h3>WE MAY ENGAGE IN INTEREST-BASED ADVERTISING</h3>
            <p>
              We and our partners display interest-based advertising using information gathered about you over time across multiple websites, devices, or other platforms. 
            </p>
            <br></br>
            <p>
              Interest-based advertising or "online behavioral advertising" includes ads served to you after you leave a website, encouraging you to return. These ads may be served after you leave our website, or after you leave the websites of third parties. They also include ads we and our partners think are relevant based on your browsing habits or online activities. These ads might be seen on websites or apps. We might serve these ads, or third parties may serve ads. They might be about our products or other companies' products.
            </p>
          </section>

          <section>
            <h3>HOW WE GATHER RELEVANT INFORMATION ABOUT YOU</h3>
            <p>
              To decide what is relevant to you, we and our partners use information made available when you interact with us, our affiliates, and other third parties. This information is gathered using the tracking tools described above. For example, we or our partners might look at your purchases or browsing behaviors. We and our partners might look at these activities on our platforms or the platforms of others.
            </p>
            <br></br>
            <p>We work with third parties who help gather this information. These third parties might link your name or email
              address to other information they collect. That might include past purchases made offline or online. Or, it might
              include online usage information.</p>
          </section>

          <section>
            <h3>YOU CAN CONTROL CERTAIN TRACKING TOOLS</h3>
            <p>
              We use tracking tools, including cookies, on our websites. Third parties also use cookies on our sites. Your browser may give you the ability to control cookies. How you do so depends on the type of cookie. Cookies are small files that download when you access certain websites. To learn more, visit <a href="https://allaboutcookies.org">here</a>. Certain browsers can be set to reject browser cookies. These pages also explain how you can manage and delete cookies. To control flash cookies, which we may use on certain websites from time to time, you can go <a href='https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html'>here</a>. Why? Because flash cookies cannot be controlled through your browser settings. If you block cookies, certain features on our sites may not work. If you block or reject cookies, not all of the tracking described here will stop.
            </p>
            <br></br>
            <p>Certain choices you make are browser and device-specific.</p>
            <br></br>
            <p id='clickhere'>To update your settings for this browser, <a href='#clickhere' onClick={this.clickDoNotSell}>click here.</a></p>
          </section>
           
          <section>
            <h3>Our Do Not Track Policy:</h3>
            <p>
              Some browsers have "do not track" features that allow you to tell a website not to track you. These features are not all uniform. We do not currently respond to these signals.
            </p>
          </section>
        </div>
      </div>
    );
  }
}

export default AboutAds;